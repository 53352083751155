import { IconButton } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Icon } from '@chakra-ui/icon';
import { Box, Flex, HStack } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/react';
import LAYOUT from '@components/Layouts/layoutConstants';
import { HeaderNavigation } from '@components/Navigation';
import dynamic from 'next/dynamic';
import { MdMenu } from 'react-icons/md';
import { useVisibleModals } from '~/contexts/ModalContext';

const HeaderMainItems = dynamic(
  () => import('@components/Navigation/Header/HeaderMainItems')
);
const SecondaryMenuItem = dynamic(
  () => import('@components/Navigation/Header/SecondaryMenuItem')
);
const NavBarLogin = dynamic(
  () => import('@components/Account/NavbarLogin/NavBarLogin')
);
const HeaderLogo = dynamic(() => import('./HeaderLogo'));

interface HeaderNonScrolledProps {
  logo: HeaderNavigation['logo'];
  main: HeaderNavigation['main'];
  secondary: HeaderNavigation['secondary'];
  mainMenuPosition?: string;
  isAccountEnabled?: boolean;
  metadata: HeaderNavigation['metadata'];
}

const HeaderNonScrolled: React.FC<HeaderNonScrolledProps> = ({
  logo,
  main,
  secondary,
  mainMenuPosition,
  isAccountEnabled = false,
  metadata,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const { visibleModals } = useVisibleModals();
  const hasVisibleModals = visibleModals.length;

  const showMainItems = Boolean(main.length);
  const showLogo = Boolean(logo);
  const showSecondaryItems = Boolean(secondary.length || isAccountEnabled);

  const gridTemplate = {
    main: useBreakpointValue({
      base: showMainItems ? 'auto' : '',
      xl: showMainItems ? '1fr' : '',
    }),
    logo: showLogo ? 'auto' : '',
    secondary: showSecondaryItems ? 'auto' : '',
  };

  return (
    <Box
      maxW={LAYOUT.CONTAINER_MAX_WIDTH}
      px={LAYOUT.CONTAINER_HORIZONTAL_PADDING}
      width="100%"
      m="auto"
      alignItems="center"
      gap={6}
      display="grid"
      gridTemplateColumns={{
        // [main items menu], [logo], [secondary items]
        base: `${gridTemplate.main} ${gridTemplate.logo} ${gridTemplate.secondary}`,
        // [logo], [main items menu], [secondary items]
        xl: `${gridTemplate.logo} ${gridTemplate.main} ${gridTemplate.secondary}`,
      }}
    >
      {showMainItems && (
        <Flex
          display={{ base: 'block', xl: 'none' }}
          // Align the icon to the start of the container on mobile.
          ml={{ base: '-8px', xl: 'unset' }}
        >
          <IconButton
            aria-label="Toggle menu"
            onClick={onToggle}
            icon={<Icon as={MdMenu} boxSize="32px" />}
            variant="ghost"
          />
        </Flex>
      )}

      {showLogo && <HeaderLogo {...logo} />}

      {showMainItems && (
        <Flex
          as="nav"
          gap={{ base: 2, xl: 4 }}
          width={{ base: '100%', xl: 'auto' }}
          backgroundColor={{ base: 'white', xl: 'transparent' }}
          py={{ base: 4, xl: '0' }}
          px={{ base: 8, xl: '0' }}
          transition={{ base: 'opacity .2s ease', xl: 'none' }}
          zIndex={hasVisibleModals ? 1 : 90000}
          alignItems={{ base: 'start', xl: 'center' }}
          direction={{ base: 'column', xl: 'row' }}
          position={{ base: 'absolute', xl: 'static' }}
          top={{ base: '63px', xl: 'auto' }}
          left={{ base: '0', xl: 'auto' }}
          right={{ base: '0', xl: 'auto' }}
          opacity={{ base: isOpen ? 100 : 0, xl: 100 }}
          pointerEvents={{ base: isOpen ? 'auto' : 'none', xl: 'auto' }}
          boxShadow={{ base: 'md', xl: 'none' }}
          {...(mainMenuPosition === 'left'
            ? { marginRight: 'auto' }
            : mainMenuPosition === 'right'
            ? { marginLeft: 'auto' }
            : {})}
        >
          <HeaderMainItems main={main} metadata={metadata} />
        </Flex>
      )}

      {showSecondaryItems && (
        <Flex
          display={{ base: 'flex', xl: 'block' }}
          textAlign="right"
          justifyContent="flex-end"
          width="100%"
          gridTemplateColumns="repeat(2, 1fr)"
          gap={3}
          alignItems="center"
          fontWeight="bold"
          fontSize="14px"
        >
          <HStack
            display="flex"
            justifyContent="end"
            alignItems="center"
            fontWeight="bold"
            gap="2"
          >
            {secondary.map((item, i) => (
              <SecondaryMenuItem key={i} item={item} metadata={metadata} />
            ))}
          </HStack>
          {isAccountEnabled && <NavBarLogin />}
        </Flex>
      )}
    </Box>
  );
};

export default HeaderNonScrolled;
